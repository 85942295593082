/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {usePage, usePreferences, useSelectedColumns, useSetPage} from '../hooks/tableState';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {rem} from '../utils/rem';
import {ISimpleObservable, simpleObservable} from '../utils/simpleObservable';
import {DataTable, DataTableProps} from 'primereact/datatable';
import {ExpansionRow, IGroupedRowData, useExpansionState, useGroups} from '../hooks/groups';
import {Button} from 'primereact/button';
import {useLocale} from '../hooks/locale';
import {Column} from 'primereact/column';
import {useData} from '../hooks/data';
import {ColumnHeader} from './ColumnHeader';
import {ExportDialogButton} from './ExportDialogButton';
import {Def} from '../utils/types';
import {cx} from '../utils/cx';
import {IColumn, ITableProps} from '../ITableProps';
import {EmptyMessage} from './EmptyMessage';
import {useObservable} from '../hooks/useObservable';

export interface IMainTableProps {
  widthObs: ISimpleObservable<number>;
  numberOfPinnedColumns?: number;
  columnExpectedWidth?: number;
  allowExport?: boolean;
  onRowClick: ITableProps<any, any, any, any>['onRowClick'];
  onRowDoubleClick: ITableProps<any, any, any, any>['onRowDoubleClick'];
  selectObs: ITableProps<any, any, any, any>['selectObs'];
  showPageReport: ITableProps<any, any, any, any>['showPageReport'];
}

export const TableBody: FC<IMainTableProps> = ({
  widthObs,
  onRowClick,
  onRowDoubleClick,
  selectObs,
  numberOfPinnedColumns: _nOPC = 1,
  columnExpectedWidth: _cEW = 250,
  allowExport = false,
  showPageReport = false
}) => {
  const [loading, {data, error, total}, forceLoad] = useData();
  const locale = useLocale();
  const page = usePage();
  const setPage = useSetPage();
  const onPage = useCallback<Def<DataTableProps['onPage']>>(
    ({first, rows}) => {
      setPage((prev) => ({...prev, first, rows}));
    },
    [setPage]
  );

  const selectedColumns = useSelectedColumns();
  const [mainColumns, pinnedToEndColumns] = split(selectedColumns, (value) => !value.pinToEnd);
  const {groupingIsActive: _groupingIsActive, groupingColumnFields} = usePreferences();

  const {openGroups, toggleGroup, groupMap, groupedData} = useGroups(data, groupingColumnFields, selectedColumns);

  // disable grouping when groupFields list is empty of undefined
  const groupingIsActive = groupingColumnFields.length > 0 && _groupingIsActive;

  const setExpansionState = useExpansionState();

  const numberOfPinnedColumns = groupingIsActive ? groupingColumnFields.length : _nOPC;
  const [numberOfVisibleColumns, _setNVC] = useState(0);
  useEffect(() => {
    return widthObs.subscribe((width) => {
      _setNVC(Math.floor(width / rem(_cEW)));
    });
  }, [_cEW, widthObs]);
  const [displayedColumnsFilter, columnSwitch] = useColumnSwitch(
    numberOfVisibleColumns,
    // when grouping is active, all grouping fields are always visible plus 'timestamp' field
    // otherwise, 'eventType' and 'timestamp' should be visible
    numberOfPinnedColumns,
    mainColumns.length
  );

  const displayedColumns = (
    groupingIsActive
      ? mainColumns.slice().sort((a, b) => {
          const indexA = groupingColumnFields.findIndex((field) => field === a.field) + 1;
          const indexB = groupingColumnFields.findIndex((field) => field === b.field) + 1;
          return (indexA || Infinity) - (indexB || Infinity);
        })
      : mainColumns
  ).filter(displayedColumnsFilter);

  const selectedRows = useObservable(selectObs ?? selectFallbackObs);
  const onSelectionChange = useMemo<DataTableProps['onSelectionChange']>(() => {
    return (
      selectObs &&
      ((e) => {
        selectObs.set(e.value ?? []);
      })
    );
  }, [selectObs]);
  const allowSelection = !!selectObs;

  return (
    <DataTable
      rowHover
      lazy
      scrollable
      loading={loading}
      loadingIcon={''}
      dataKey={'id'}
      css={$tableRoot}
      value={groupingIsActive ? groupedData : data}
      emptyMessage={<EmptyMessage />}
      onRowClick={onRowClick}
      onRowDoubleClick={onRowDoubleClick}
      // Sort
      sortField={page.sortField || undefined}
      sortOrder={page.sortOrder}
      // Page
      paginator
      onPage={onPage}
      first={page.first}
      rows={page.rows}
      totalRecords={total}
      rowsPerPageOptions={page.rowsPerPage}
      paginatorTemplate={`${
        showPageReport ? 'CurrentPageReport ' : ''
      }RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink`}
      paginatorLeft={<div />}
      paginatorRight={
        <div css={paginatorRightCss}>
          <Button
            className={'p-button-secondary'}
            icon={'mdi mdi-refresh'}
            tooltip={locale.refresh}
            tooltipOptions={{position: 'top'}}
            data-cy='refresh'
            onClick={forceLoad}
          />
          {allowExport && <ExportDialogButton pagesTotal={Math.ceil(total / page.rows)} />}
        </div>
      }
      currentPageReportTemplate={`{first}-{last} ${locale.from} {totalRecords}`}
      selection={allowSelection && selectedRows}
      onSelectionChange={onSelectionChange}
    >
      {allowSelection && <Column selectionMode="multiple" headerStyle={{width: 'calc(50rem / var(--bfs))'}} />}
      {displayedColumns.map(mapColumn)}
      {pinnedToEndColumns.map(mapColumn)}
      {(groupingIsActive || numberOfVisibleColumns < mainColumns.length) && (
        <Column
          key={'$groupKey'}
          field={'$groupKey'}
          header={'exp'}
          style={{width: 'calc(80rem / var(--bfs))', padding: '0'}}
          filter={true}
          filterElement={columnSwitch}
          body={(rowData: IGroupedRowData<{}>) => {
            if (!groupingIsActive) {
              return null;
            }
            const group = groupMap.get(rowData.$groupKey);
            return (
              group && (
                <ExpansionRow
                  event={rowData}
                  group={group}
                  open={openGroups.has(rowData.$groupKey)}
                  groupingFieldsLength={groupingColumnFields.length}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleGroup(rowData.$groupKey);
                  }}
                  setExpansionState={setExpansionState}
                />
              )
            );
          }}
        />
      )}
    </DataTable>
  );
};

const mapColumn = (col: IColumn<any, any>) => {
  return (
    <Column
      key={col.field}
      sortable={!!col.sortable}
      field={col.field}
      header={col.header}
      body={col.body}
      style={col.style}
      filter={true}
      filterElement={<ColumnHeader column={col} />}
    />
  );
};

const $tableRoot = css`
  word-break: break-word;
  table.p-datatable-scrollable-header-table > thead > tr:first-of-type {
    display: none;
  }
  .p-datatable-scrollable-body {
    overflow-y: scroll;
  }
`;

// language=SCSS
const paginatorRightCss = css`
  & > * {
    margin-right: calc(10rem / var(--bfs));
  }
`;

const useColumnSwitch = (
  numberOfVisibleColumns: number,
  numberOfPinnedColumns: number,
  numberOfTotalColumns: number
) => {
  const [visibleColumnsOffset, setVisibleColumnsOffset] = useState(0);
  useEffect(() => {
    setVisibleColumnsOffset(0);
  }, [numberOfVisibleColumns]);
  const _switch = (
    <ColumnsSwitch
      min={0}
      max={
        numberOfPinnedColumns < numberOfVisibleColumns
          ? numberOfTotalColumns - numberOfVisibleColumns
          : numberOfTotalColumns - numberOfPinnedColumns - 1
      }
      current={visibleColumnsOffset}
      onPrev={() => setVisibleColumnsOffset((prev) => prev - 1)}
      onNext={() => setVisibleColumnsOffset((prev) => prev + 1)}
    />
  );
  const filterColumns = (_: unknown, index: number) => {
    const total = numberOfPinnedColumns >= numberOfVisibleColumns ? numberOfPinnedColumns + 1 : numberOfVisibleColumns;
    return (
      index < numberOfPinnedColumns ||
      (numberOfPinnedColumns + visibleColumnsOffset <= index && index < total + visibleColumnsOffset)
    );
  };
  return [filterColumns, _switch] as const;
};

const ColumnsSwitch: FC<{min: number; max: number; current: number; onNext: () => void; onPrev: () => void}> = ({
  min,
  max,
  current,
  onNext,
  onPrev
}) => {
  return (
    <div css={columnsSwitchCss}>
      <Button
        disabled={current <= min}
        className={cx([
          'p-button-rounded p-button-outlined p-button-sm',
          current <= min ? 'p-button-secondary' : 'p-button-primary'
        ])}
        icon={'mdi mdi-24px mdi-chevron-left'}
        data-cy='to-left-btn'
        onClick={onPrev}
      />
      <Button
        disabled={current >= max}
        className={cx([
          'p-button-rounded p-button-outlined p-button-sm',
          current >= max ? 'p-button-secondary' : 'p-button-primary'
        ])}
        icon={'mdi mdi-24px mdi-chevron-right'}
        data-cy='to-right-btn'
        onClick={onNext}
      />
    </div>
  );
};

// language=SCSS
const columnsSwitchCss = css`
  & {
    display: flex;
    justify-content: flex-end;
  }
  & > button:first-of-type {
    margin-right: calc(10rem / var(--bfs));
  }
`;

const selectFallbackObs = simpleObservable([]);

const split = <T,>(arr: T[], predicate: (value: T) => boolean) => {
  const left: T[] = [];
  const right: T[] = [];
  arr.forEach((value) => {
    if (predicate(value)) {
      left.push(value);
    } else {
      right.push(value);
    }
  });
  return [left, right];
};
