/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {isEqual} from 'lodash-es';
import {IColumn} from '../ITableProps';
import {
  useFilter,
  useFilterDefaults,
  useFilterDescription,
  usePage,
  usePageDefaults,
  useSetPage
} from '../hooks/tableState';
import {Close} from './ui/Close';
import {Tune} from './ui/Tune';
import {createPortal} from 'react-dom';
import {
  isAutoCompleteFilter,
  isEnumFilter,
  isEnumLazyFilter,
  isRangeFilter,
  isSearchFilter,
  isTextFilter,
  isTimeFilter
} from '../filterTypes';
import {TextFilter} from './filters/TextFilter';
import {EnumFilter} from './filters/EnumFilter';
import {RangeFilter} from './filters/RangeFilter';
import {TimeFilter} from './filters/TimeFilter';
import {SearchFilter} from './filters/SearchFilter';
import {AutoCompleteFilter} from './filters/AutoCompleteFilter';
import {rem} from '../utils/rem';
import {isString} from '../utils/basicValidators';

export interface IColumnHeaderProps<K extends string> {
  column: IColumn<{}, K>;
}

export function ColumnHeader<K extends string>({column}: IColumnHeaderProps<K>) {
  const {field, header, filterable} = column;
  const [isOpen, setOpen] = useState(false);
  const defaults = useFilterDefaults();
  const filter = useFilter();
  const ref = useRef<HTMLDivElement | null>(null);
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    if (isOpen && node) {
      const {left, bottom} = node.getBoundingClientRect();
      const div = document.createElement('div');
      div.style.position = 'absolute';
      if (window.innerHeight - bottom <= rem(600)) {
        div.style.top = bottom - rem(100) + 'px';
      } else {
        div.style.top = bottom + 'px';
      }
      if (window.innerWidth - left <= rem(400)) {
        div.style.left = left - rem(100) + 'px';
      } else {
        div.style.left = left + 'px';
      }
      document.body.appendChild(div);
      setPortalNode(div);
      let pass = true;
      const onInside = () => {
        pass = true;
      };
      const onOutside = () => {
        if (pass) {
          pass = false;
        } else {
          setOpen(false);
        }
      };
      div.addEventListener('click', onInside);
      document.body.addEventListener('click', onOutside);
      return () => {
        div.removeEventListener('click', onInside);
        document.body.removeEventListener('click', onOutside);
        document.body.removeChild(div);
        setPortalNode(null);
      };
    }
    return undefined;
  }, [isOpen]);
  useEffect(() => {
    return () => setOpen(false);
  }, [filter]);
  const changed = !isEqual(filter[field], defaults[field]);
  return (
    <div ref={ref} css={mainCss}>
      <div>
        <div css={headerCss} data-cy='column-header'>{header}</div>
        {(field in filter && filterable !== false) && (
          <div>
            <ToggleFilterButton changed={changed} isOpen={isOpen} onClick={() => setOpen((val) => !val)} />
          </div>
        )}
        {column.sortable && <SortButton sortField={isString(column.sortable) ? column.sortable : field} />}
      </div>
      {portalNode &&
        createPortal(
          <div className={'p-raised'} css={popupCss}>
            <FilterField field={field} />
          </div>,
          portalNode
        )}
    </div>
  );
}

// language=SCSS
const mainCss = css`
  & {
    position: relative;
    > div:nth-child(1) {
      display: flex;
      align-items: center;

      > * {
        margin-right: calc(10rem / var(--bfs));
      }
      > *:last-child {
        margin-right: 0;
      }
    }
    > div:nth-child(2) {
      position: absolute;
      z-index: 3;
    }
  }
`;

// language=SCSS
const headerCss = css`
  & {
    height: 1em;
    display: flex;
    align-items: center;
    position: relative;
    font-size: calc(16rem / var(--bfs));
    font-weight: 500;
    color: var(--text-color);
  }
`;

// language=SCSS
const popupCss = css`
  & {
    position: relative;
    background: var(--surface-b);
    border-radius: var(--border-radius);
    margin-top: calc(15rem / var(--bfs));
  }
`;

const SortButton: FC<{sortField: string}> = ({sortField}) => {
  const page = usePage();
  const pageDefaults = usePageDefaults();
  const setPage = useSetPage();
  const icon =
    page.sortField === sortField
      ? page.sortOrder === -1
        ? 'pi-sort-amount-up'
        : 'pi-sort-amount-down-alt'
      : 'pi-sort-alt';
  const iconTitle =
    page.sortField === sortField ? (page.sortOrder === -1 ? 'По возрастанию' : 'По убыванию') : 'Без сортировки';
  const onClick = useCallback(() => {
    setPage((prev) => {
      if (typeof pageDefaults.sortField === 'string' && pageDefaults.sortField === sortField) {
        if (prev.sortField === null) {
          return {...prev, sortField, sortOrder: pageDefaults.sortOrder};
        } else if (prev.sortOrder === pageDefaults.sortOrder) {
          return {...prev, sortOrder: pageDefaults.sortOrder === 1 ? -1 : 1};
        } else {
          return {...prev, sortField: null};
        }
      } else if (prev.sortField === sortField) {
        if (prev.sortOrder === pageDefaults.sortOrder) {
          return {...prev, sortOrder: pageDefaults.sortOrder === 1 ? -1 : 1};
        } else {
          return {...prev, sortField: null};
        }
      } else {
        return {...prev, sortField: sortField, sortOrder: pageDefaults.sortOrder};
      }
    });
  }, [setPage, sortField, pageDefaults]);
  return (
    <div css={sortCss} role={'button'} onClick={onClick} title={iconTitle}>
      <span className={`pi ${icon}`} />
    </div>
  );
};
// language=SCSS
const sortCss = `&{
  position: relative;
  
  height: calc(20rem / var(--bfs));
  width: calc(20rem / var(--bfs));
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  > span {
    font-size: calc(14rem / var(--bfs));
  }
  
  color: var(--text-color-secondary);
  :hover {
    color: var(--text-color);
    cursor: pointer;
  }
  :active {
    color: var(--text-color-secondary);
  }
}`;

const ToggleFilterButton: FC<{isOpen: boolean; onClick: () => void; changed: boolean}> = ({
  isOpen,
  changed,
  onClick
}) => {
  return (
    <button css={toggleFilterButtonCss} onClick={onClick}>
      {changed && <div css={changedMarkCss} />}
      {isOpen ? <Close /> : <Tune data-cy='icon-tune'/>}
    </button>
  );
};

// language=SCSS
const toggleFilterButtonCss = css`
  & {
    position: relative;

    height: calc(20rem / var(--bfs));
    width: calc(20rem / var(--bfs));
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0 none;
    display: block;

    cursor: pointer;

    > svg > path {
      fill: var(--text-color-secondary);
      transition: fill var(--transition-duration) ease;
    }

    &:hover > svg > path {
      fill: var(--text-color);
    }

    &:focus {
      outline: none;
    }
  }
`;

// language=SCSS
const changedMarkCss = css`
  & {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);

    border-radius: 50%;
    height: calc(10rem / var(--bfs));
    width: calc(10rem / var(--bfs));
    background: var(--primary-color);
  }
`;

function FilterField<K extends string>({field}: {field: K}) {
  const description = useFilterDescription<K>()[field];
  if (isTextFilter(description)) {
    return <TextFilter field={field} description={description} />;
  } else if (isEnumFilter(description) || isEnumLazyFilter(description)) {
    return <EnumFilter field={field} description={description} />;
  } else if (isRangeFilter(description)) {
    return <RangeFilter field={field} description={description} />;
  } else if (isTimeFilter(description)) {
    return <TimeFilter field={field} description={description} />;
  } else if (isSearchFilter(description)) {
    return <SearchFilter field={field} description={description} />;
  } else if (isAutoCompleteFilter(description)) {
    return <AutoCompleteFilter field={field} description={description} />;
  }
  return null;
}
