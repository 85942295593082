/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Checkbox, CheckboxProps} from 'primereact/checkbox';
import {FC, useState} from 'react';
import {genElementId} from '../../utils/genElementId';

export type ICheckboxFieldProps = CheckboxProps & {label: string; labelHeight?: string};

export const CheckboxField: FC<ICheckboxFieldProps> = ({label, labelHeight, ...rest}) => {
  const [id] = useState(genElementId);
  return (
    <div className={['p-field-checkbox', rest.disabled && 'p-disabled'].filter(Boolean).join(' ')} data-cy='checkbox-item'>
      <Checkbox {...rest} id={id} />
      <label htmlFor={id} style={{height: labelHeight}} data-cy='checkbox-label'>
        {label}
      </label>
    </div>
  );
};
