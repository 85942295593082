/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, Suspense, Fragment, useCallback, useState, lazy} from 'react';
import {useLocale} from '../hooks/locale';
import {useDataSource} from '../hooks/tableState';
import {Button} from 'primereact/button';

const ExportDialog = lazy(() => import('./ExportDialogLazy'));

export const ExportDialogButton: FC<{pagesTotal: number}> = ({pagesTotal}) => {
  const locale = useLocale();
  const [visible, setVisible] = useState(false);
  const onShow = useCallback(() => setVisible(true), []);
  const onHide = useCallback(() => setVisible(false), []);
  const dataSource = useDataSource();
  const ngsiDataSource = 'type' in dataSource ? dataSource : undefined;
  return (
    <Fragment>
      {ngsiDataSource && (
        <Button
          className={'p-button-secondary'}
          icon={'mdi mdi-database-export'}
          tooltip={locale.exportLocale.title}
          tooltipOptions={{position: 'top'}}
          data-cy='export'
          onClick={onShow}
        />
      )}
      {ngsiDataSource && visible && (
        <Suspense fallback={null}>
          <ExportDialog dataSource={ngsiDataSource} onHide={onHide} pagesTotal={pagesTotal} />
        </Suspense>
      )}
    </Fragment>
  );
};
