/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, useCallback} from 'react';
import {InputText, InputTextProps} from 'primereact/inputtext';
import {paddingCss, widthCss} from './styles';
import {Def} from '../../utils/types';
import {FilterActions} from '../FilterActions';
import {isString} from '../../utils/basicValidators';
import {useCancelFilter, useNextFilter, useSetNextFilter} from '../../hooks/nextFilter';
import {branch} from '../../utils/branch';
import {ITextFilter} from '../../filterTypes';

export const TextFilter: FC<{
  field: string;
  description: ITextFilter;
}> = ({field, description: {placeholder, transform}}) => {
  const nextFiler = useNextFilter();
  const setNextFilter = useSetNextFilter();
  const cancelFilter = useCancelFilter();
  const onChange = useCallback<Def<InputTextProps['onChange']>>(
    (e: any) => {
      branch(isString)(e?.target?.value)(
        (value) =>
          setNextFilter((prev) => ({
            ...prev,
            [field]: transform ? transform(value) : value
          })),
        () => cancelFilter(field)
      );
    },
    [field, setNextFilter, cancelFilter]
  );
  return (
    <div css={[paddingCss, widthCss]}>
      <div className={'p-fluid p-input-filled p-input-icon-left'} data-cy='search-filter-item'>
        <i className={'pi pi-search'} data-cy='search-filter-icon' />
        <InputText placeholder={placeholder} type={'text'} data-cy='search-filter-input' value={nextFiler[field] as any} onChange={onChange} />
      </div>
      <FilterActions field={field} />
    </div>
  );
};
